export var GridBreakpoint;
(function (GridBreakpoint) {
    GridBreakpoint["MQ1"] = "mq1";
    GridBreakpoint["MQ2"] = "mq2";
    GridBreakpoint["MQ3"] = "mq3";
    GridBreakpoint["MQ4"] = "mq4";
    GridBreakpoint["MQ5"] = "mq5";
})(GridBreakpoint || (GridBreakpoint = {}));
export function getBreakpointMinWidth(breakpoint) {
    return {
        mq1: 0,
        mq2: 476,
        mq3: 760,
        mq4: 1024,
        mq5: 1280,
    }[breakpoint];
}
